<template>
  <Modal :value="visible"
         :title="title"
         width="900"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="drawer">
      <div class="drawer-cont">
        <div class="drawer-cont-right">
          <div id="enclosureModalMapDiv"
               class="mapDiv"
               ref="enclosureModalMapDiv">
          </div>
          <div class="map-top">
            <AutoComplete ref="search"
                          v-model="searchValue"
                          placeholder=""
                          size="large"
                          @on-search="onSearch"
                          @on-select="onSelect"
                          style="width:300px">
              <Option v-for="item in searchData"
                      :value="item.hotPointID"
                      :key="item.hotPointID">{{ item.name }}</Option>
            </AutoComplete>
            <!-- 区域显示 -->
            <div class="map-top-btns"
                 v-show="type===1">
              <span @click.stop="onClickResetDraw(1)"
                    :class="{current:drawType===1}">
                <Icon custom="i-icon icon-hangzhengquyu"
                      size="16" />
                圆形区域
              </span>
              <span @click.stop="onClickResetDraw(2)"
                    :class="{current:drawType===2}">
                <Icon custom="i-icon icon-duoxuan"
                      size="16" />
                矩形区域
              </span>
              <span @click.stop="onClickResetDraw(3)"
                    :class="{current:drawType===3}">
                <Icon custom="i-icon icon-duobianxing"
                      size="16" />
                多边形区域
              </span>
            </div>
            <div class="map-top-btns"
                 @click.stop="onClickResetDraw(drawType)"
                 v-show="resetVisible">
              <span>
                <Icon custom="i-icon icon-saoba"
                      size="16" />
                重绘
              </span>
            </div>

          </div>
        </div>
      </div>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import tMap from '@/utils/tMap'
import MyTreeSelect from '@/components/common/MyTreeSelect';
export default {
  components: {
    MyTreeSelect
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '区域'
    },
    dataId: String,
  },
  data () {
    return {
      loading: false,
      height: 0,
      name: '', // 网格名称
      depart: '', // 所属机构
      departId: '', // 所属机构id
      type: 1, // 网格类型
      typeArr: [
        {
          id: 1,
          name: '区域'
        },
        {
          id: 2,
          name: '线路'
        },
        {
          id: 3,
          name: '路段'
        }
      ],
      sideWidth: '', // 描边宽度
      sideWidthArr: [
        {
          id: 2,
          name: 2
        },
        {
          id: 3,
          name: 3
        },
        {
          id: 4,
          name: 4
        },
        {
          id: 5,
          name: 5
        },
        {
          id: 6,
          name: 6
        },
        {
          id: 7,
          name: 7
        },
        {
          id: 8,
          name: 8
        },
        {
          id: 9,
          name: 9
        },
        {
          id: 10,
          name: 10
        },
        {
          id: 11,
          name: 11
        },
        {
          id: 12,
          name: 12
        },
        {
          id: 13,
          name: 13
        },
        {
          id: 14,
          name: 14
        },
        {
          id: 15,
          name: 15
        },
        {
          id: 16,
          name: 16
        },
        {
          id: 17,
          name: 17
        },
        {
          id: 18,
          name: 18
        },
        {
          id: 19,
          name: 19
        },
        {
          id: 20,
          name: 20
        },
      ],
      section: '', // 路段
      sectionId: '', // 路段id
      sectionArr: [],
      area: '', // 面积
      lineWidth: '20', // 线宽
      guideboard: 1, // 设置为路牌
      mileage: '', // 里程

      // 监管规则 start
      supervisionTime: '',
      advance: '',
      late: '',
      leaveEarly: '',
      absenteeism: '',
      // 监管规则 end

      // 地图相关 start
      map: null,  // 天地图实例
      zoom: 14, // 初始地图缩放等级
      searchValue: '',
      searchData: [],
      config: {
        color: "#0079fe",
        weight: 3,
        opacity: 0.8,
        fillColor: "#0079fe",
        fillOpacity: 0.3
      },
      drawType: 1, // 地图按钮
      drawTool: null,
      overlay: null,
      resetVisible: false
      // 地图相关 end
    };
  },
  watch: {
    visible (newVal) {
      newVal && this.init()
    },
    dataId (newValue) {
      newValue && this.getDetail()
    },
  },
  computed: {
    ...mapGetters({
      'mapCenterLnglat': 'getMapCenterLnglat',
      'departmentTreeArr': 'getDepartmentTreeList',
    }),
  },
  mounted () {
    this.height = window.innerHeight - 160
    this.initData()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
    ]),
    init () {
      if (!this.map) this.createMap()
      this.updateDepartmentTreeList()
    },
    initData () {
    },
    getDetail () {
      this.$store.dispatch('getGridDetail', { gridId: this.dataId }).then(res => {
        let points
        let viewport
        for (const i in res) {
          this[i] = res[i]
        }
        switch (res.drawType) {
          case 3:
            // 圆形
            this.drawType = 1
            this.drawTool && this.drawTool.close()
            this.resetVisible = true
            this.drawTool = new T.CircleTool(this.map, this.config);
            points = new T.LngLat(res.lng, res.lat)
            this.overlay = new T.Circle(points, res.radius, this.config)
            this.overlay.enableEdit()
            this.$nextTick(() => {
              // 监听可拖动点的dom 鼠标按下事件
              let prevBtn = document.querySelector('.mapDiv .tdt-edit-resize')
              prevBtn.addEventListener("mouseup", this.onCircleResize);
              prevBtn.addEventListener("click", this.onCircleResize);
            })
            this.map.addOverLay(this.overlay)
            let bounds = this.overlay.getBounds()
            viewport = this.map.getViewport([bounds.Lq, bounds.kq])
            this.map.panTo(viewport.center, viewport.zoom)
            // 计算面积
            let radius = this.overlay.getRadius()
            this.area = parseInt(Math.PI * radius * radius)
            break;
          case 4:
            // 矩形
            this.drawType = 2
            this.drawTool && this.drawTool.close()
            this.resetVisible = true
            this.drawTool = new T.RectangleTool(this.map, this.config);
            points = JSON.parse(res.points)
            this.overlay = new T.Rectangle([points.Lq, points.kq], this.config)
            this.overlay.enableEdit()
            this.$nextTick(() => {
              // 监听可拖动点的dom 鼠标按下事件
              let prevBtn = document.getElementsByClassName("tdt-edit-resize")
              prevBtn.forEach(item => {
                item.addEventListener("mouseup", this.onRectangleResize);
                item.addEventListener("click", this.onRectangleResize);
              })
            })
            this.map.addOverLay(this.overlay)
            viewport = this.map.getViewport([points.Lq, points.kq])
            this.map.panTo(viewport.center, viewport.zoom)
            // 计算面积
            let point1 = this.overlay.getBounds().Lq
            let point2 = this.overlay.getBounds().kq
            let point3 = new T.LngLat(point1.lng, point2.lat)
            let width = this.map.getDistance(point1, point3)
            let height = this.map.getDistance(point2, point3)
            this.area = parseInt(width * height)
            break;
          case 5:
            // 多边形
            this.drawType = 3
            this.drawTool && this.drawTool.close()
            this.resetVisible = true
            this.drawTool = new T.PolygonTool(this.map, this.config);
            points = JSON.parse(res.points)
            this.overlay = new T.Polygon(points, this.config)
            this.overlay.enableEdit()
            this.$nextTick(() => {
              // 监听可拖动点的dom 鼠标按下事件
              let prevBtn = document.getElementsByClassName("tdt-marker-draggable")
              prevBtn.forEach(item => {
                item.addEventListener("mouseup", this.onPolygonResize);
                item.addEventListener("click", this.onPolygonResize);
              })
            })
            this.map.addOverLay(this.overlay)
            let lnglatList = [];
            points.forEach(pList => {
              // pList.forEach(p => {
              let lnglat = new T.LngLat(pList.lng, pList.lat)
              lnglatList.push(lnglat)
              // })
            })
            viewport = this.map.getViewport(lnglatList)
            this.map.panTo(viewport.center, viewport.zoom)
            // 计算面积
            let tool = new T.PolygonTool(this.map, this.config)
            this.area = parseInt(tool.getArea(lnglatList))
            break;
          default:
            break;
        }
        this.supervisionTime = [res.startTime, res.endTime]
      })
    },
    // 递归
    getSelectSectionList (sectionIdList, sectionArr) {
      let result = []
      sectionArr.forEach(item => {
        if (sectionIdList.includes(item.id)) {
          let resultItem = { km: item.km, area: item.area, lnglat: item.lnglat }
          result.push(resultItem)
        }
        if (item.children && item.children.length > 0) {
          this.getSelectSectionList(sectionIdList, item.children).forEach(i => {
            result.push(i)
          })
        }
      })
      return result;
    },
    // 生成地图
    createMap () {
      tMap.init().then((T) => {
        this.map = new T.Map('enclosureModalMapDiv')
        this.setMapCenterOrZoom()
        !this.dataId && this.drawCircle()
      }).catch(err => {
        console.log(err);
        this.$Message.info('地图加载失败,请刷新浏览器')
      })
    },
    // 设置地图中心点与缩放等级
    setMapCenterOrZoom () {
      let lnglat
      if (this.mapCenterLnglat) {
        let mapCenterLnglat = this.mapCenterLnglat.split(',')
        lnglat = new T.LngLat(mapCenterLnglat[0], mapCenterLnglat[1])
      } else {
        lnglat = new T.LngLat(119.130974, 36.706688)
      }
      this.map.centerAndZoom(lnglat, this.zoom)
    },
    // 搜索地址
    onSearch (val) {
      this.searchValue = val
      let config = {
        pageCapacity: 10,
        onSearchComplete: this.onSearchAddress
      }
      let LocalSearch = new T.LocalSearch(this.map, config)
      LocalSearch.search(this.searchValue, 1)
    },
    onSearchAddress (e) {
      this.searchData = e.getPois()
    },
    onSelect (val) {
      let obj = this.searchData.find(item => item.hotPointID === val)
      this.$nextTick(() => {
        this.searchValue = obj.name
      })
      let arr = obj.lonlat.split(' ')
      console.log(arr);
      let zoom = 16
      //创建图片对象
      let icon = new T.Icon({
        iconUrl: require('../../../../../assets/main/guidepost.png'),
        iconSize: new T.Point(24, 32),
        iconAnchor: new T.Point(10, 30)
      })
      let lnglat = new T.LngLat(arr[0], arr[1])
      let marker = new T.Marker(lnglat, { icon: icon })
      this.map.addOverLay(marker)
      this.map.panTo(new T.LngLat(arr[0], arr[1]), zoom)
    },
    // 圆形区域
    drawCircle () {
      this.drawTool = new T.CircleTool(this.map, this.config);
      this.drawTool.open();
      //绑定drawend事件 用户完成绘制圆时触发
      this.drawTool.addEventListener("drawend", this.getCirclePoints);
    },
    // 获取圆形区域坐标
    getCirclePoints (e) {
      this.resetVisible = true
      this.overlay = e.currentCircle
      this.overlay.enableEdit()
      //中心点坐标
      let radius = e.currentRadius
      this.area = parseInt(Math.PI * radius * radius)
      // this.points = e.currentCenter
      // 监听可拖动点的dom 鼠标按下事件
      let prevBtn = document.querySelector('.mapDiv .tdt-edit-resize')
      prevBtn.addEventListener("mouseup", this.onCircleResize);
      prevBtn.addEventListener("click", this.onCircleResize);
    },
    // 计算圆形  修改后面积
    onCircleResize () {
      let radius = this.overlay.getRadius()
      this.area = parseInt(Math.PI * radius * radius)
    },
    // 矩形区域
    drawRectangle () {
      this.drawTool = new T.RectangleTool(this.map, this.config);
      this.drawTool.open();
      //绑定draw事件 用户每次完成拉框操作时触发事件。
      this.drawTool.addEventListener("draw", this.getRectanglePoints);
    },
    // 获取矩形区域坐标
    getRectanglePoints (e) {
      this.resetVisible = true
      this.overlay = e.currentRectangle
      this.overlay.enableEdit()
      // 计算矩形面积
      let point1 = this.overlay.getBounds().Lq
      let point2 = this.overlay.getBounds().kq
      let point3 = new T.LngLat(point1.lng, point2.lat)
      let width = this.map.getDistance(point1, point3)
      let height = this.map.getDistance(point2, point3)
      this.area = parseInt(width * height)
      // this.points = e.currentBounds
      // 监听可拖动点的dom 鼠标按下事件
      let prevBtn = document.getElementsByClassName("tdt-edit-resize")
      prevBtn.forEach(item => {
        item.addEventListener("mouseup", this.onRectangleResize);
        item.addEventListener("click", this.onRectangleResize);
      })
    },
    // 计算矩形 修改后面积
    onRectangleResize () {
      // this.overlay = this.drawTool.getRectangles()[0]
      let point1 = this.overlay.getBounds().Lq
      let point2 = this.overlay.getBounds().kq
      let point3 = new T.LngLat(point1.lng, point2.lat)
      let width = this.map.getDistance(point1, point3)
      let height = this.map.getDistance(point2, point3)
      this.area = parseInt(width * height)
    },
    // 多边形区域
    drawPolygon () {
      this.drawTool = new T.PolygonTool(this.map, this.config);
      this.drawTool.open();
      //绑定draw事件 用户双击完成一次折线绘制时触发事件。
      this.drawTool.addEventListener("draw", this.getPolygonPoints);
    },
    // 获取多边形区域坐标
    getPolygonPoints (e) {
      this.resetVisible = true
      this.overlay = e.currentPolygon
      this.overlay.enableEdit()
      this.area = parseInt(e.currentArea)
      //用户当前绘制的多边形的点坐标数组
      // this.points = e.currentLnglats
      // 监听可拖动点的dom 鼠标按下事件
      let prevBtn = document.getElementsByClassName("tdt-marker-draggable")
      prevBtn.forEach(item => {
        item.addEventListener("mouseup", this.onPolygonResize);
        item.addEventListener("click", this.onPolygonResize);
      })
    },
    // 计算多边形 修改后面积
    onPolygonResize () {
      let lnglatList = [];
      this.overlay.getLngLats().forEach(pList => {
        pList.forEach(p => {
          let lnglat = new T.LngLat(p.lng, p.lat)
          lnglatList.push(lnglat)
        })
      })
      this.area = parseInt(this.drawTool.getArea(lnglatList))
    },
    // 点击重绘
    onClickResetDraw (type) {
      this.drawType = type
      // this.points = null
      this.overlay = null
      // this.radius = ''
      this.resetVisible = false
      this.drawTool && this.drawTool.close()
      this.map.clearOverLays()
      this.mileage = ''
      this.area = ''
      switch (this.drawType) {
        case 1:
          this.drawCircle()
          break;
        case 2:
          this.drawRectangle()
          break;
        case 3:
          this.drawPolygon()
          break;
        default:
          break;
      }
    },
    // 点击自定义路段
    onClickResetLine () {
      this.drawLine()
    },
    onClickConfirm () {
      if (!this.overlay) return this.$Message.info('请绘制区域');
      let params = {
        type: 1,
        gridType: 3,
      }
      switch (this.drawType) {
        case 1:
          params.drawType = 3
          params.lng = this.overlay.getCenter().lng
          params.lat = this.overlay.getCenter().lat
          params.radius = this.overlay.getRadius()
          break;
        case 2:
          params.drawType = 4
          let data = this.overlay.getBounds()
          params.rectangularEntity = {
            lq: data.Lq,
            kq: data.kq
          }
          break;
        case 3:
          params.drawType = 5
          params.pointsNew = this.overlay.getLngLats()
          params.pointsNew = params.pointsNew[0]
          break;
        default:
          break;
      }
      if (this.dataId) params.id = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditGrid', params).then(res => {
        this.loading = false
        this.$emit('onClickConfirm', res.result)
        this.$emit('onChange', false)
        this.initData()
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('update:dataId', '')
        this.$emit('onChange', false)
      }
    },
    resetData () {
      this.name = ''
      this.departId = ''
      this.depart = ''
      this.type = 1
      this.sideWidth = ''
      this.sectionId = ''
      this.section = ''
      this.area = ''
      this.lineWidth = '20'
      this.guideboard = 1
      this.mileage = ''
      this.onClickResetDraw(1)
      this.searchValue = ''
      this.searchData = []
      setTimeout(() => {
        this.setMapCenterOrZoom()
      }, 100);
      // 监管时间
      this.supervisionTime = ''
      this.advance = ''
      this.late = ''
      this.leaveEarly = ''
      this.absenteeism = ''
    }
  },
}
</script>


<style lang='scss' scoped>
::v-deep {
  .ivu-drawer-wrap {
    z-index: 2000;
  }
  .ivu-drawer {
    top: 60px;
    bottom: 0;
    .ivu-drawer-body {
      padding: 0;
    }
  }
  .modal-cont .form-ul .form-ul-li .ivu-checkbox-default {
    line-height: 1;
  }
}
.modal-cont {
  max-height: none;
}
.drawer {
  height: 600px;
  display: flex;
  flex-direction: column;
  .drawer-cont {
    flex: 1;
    display: flex;
    .drawer-cont-left {
      width: 480px;
      // height: 100%;
      padding: 0 10px;
      // overflow: auto;
      .li-color {
        display: flex;
        align-items: center;
        li {
          width: 22px;
          height: 22px;
          margin-right: 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          span {
            width: 15px;
            height: 15px;
            cursor: pointer;
            display: flex;
          }
        }
        li:nth-child(1) span {
          border: 1px solid #1f86ea;
          background: rgba(31, 134, 234, 0.2);
        }
        li:nth-child(2) span {
          border: 1px solid #129b3a;
          background: rgba(18, 155, 58, 0.2);
        }
        li:nth-child(3) span {
          border: 1px solid rgb(244, 147, 58);
          background: rgba(244, 147, 58, 0.2);
        }
        li:nth-child(4) span {
          border: 1px solid rgb(71, 85, 238);
          background: rgba(71, 85, 238, 0.2);
        }
        li:nth-child(5) span {
          border: 1px solid rgb(244, 57, 57);
          background: rgba(244, 57, 57, 0.2);
        }
        .current {
          background: #bbdaf9;
        }
      }
    }
    .drawer-cont-right {
      flex: 1;
      position: relative;
      .mapDiv {
        width: 100%;
        height: 100%;
      }
      .map-top {
        display: flex;
        z-index: 500;
        position: absolute;
        top: 20px;
        left: 20px;
        .map-top-btns {
          margin-left: 40px;
          display: flex;
          align-items: center;
          padding: 0 10px;
          color: #000;
          border-radius: 4px;
          background: #ffffff;
          box-shadow: 0 2px 1px 0 rgba(31, 132, 235, 0.2);
          span {
            margin-right: 10px;
            display: flex;
            align-items: center;
            cursor: pointer;
            i {
              margin-right: 4px;
            }
          }
          span:last-child {
            margin-right: 0;
          }
          .current {
            color: #0079fe;
          }
        }
      }
      .map-bottom {
        height: 40px;
        padding: 0 10px;
        display: flex;
        align-items: center;
        border-radius: 4px;
        color: #000;
        background: #ffffff;
        box-shadow: 0 2px 1px 0 rgba(31, 132, 235, 0.2);
        z-index: 500;
        position: absolute;
        bottom: 20px;
        left: 20px;
        span {
          margin-right: 4px;
        }
      }
    }
  }
  .drawer-footer {
    height: 50px;
    line-height: 50px;
    background: #ffffff;
    border-top: 1px solid #e1e3e5;
    text-align: right;
    padding: 0 20px;
    button {
      margin-left: 6px;
    }
  }
}
.map-grid {
  ::v-deep {
    .ivu-drawer {
      top: 0;
    }
  }
  .drawer {
    height: calc(100%);
  }
}
</style>