<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title">
            <span class="li-must">*</span>
            树种名称：
          </p>
          <Input v-model="name"
                 maxlength="30"
                 v-stringLimit
                 placeholder="树种名称"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">科属：</p>
          <Input v-model="family"
                 maxlength="30"
                 v-stringLimit
                 placeholder="科属"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>生长类型：</p>
          <Select v-model="growthType"
                  placeholder="生长类型">
            <Option :value="item.id"
                    v-for="item in growthTypeArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="li-title">介绍说明：</p>
          <Input v-model="introduction"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="介绍说明" />
        </li>
        <li class="form-ul-li">
          <p class="li-title">养护建议：</p>
          <Input v-model="maintenanceAdvice"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="养护建议" />
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="30"
                 v-stringLimit
                 placeholder="备注"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>标识：</p>
          <Select v-model="identifying"
                  placeholder="标识">
            <img :src="getIconUrl"
                 slot="prefix"
                 v-if="identifying"
                 style="width:20px;height:20px" />
            <Option :value="item.id"
                    v-for="item in identifyingArr"
                    :key="item.id">
              <img :src="item.name">
            </Option>
          </Select>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '树种'
    },
    dataId: String,
  },
  data () {
    return {
      name: '',
      family: '',
      growthType: 1,
      introduction: '',
      maintenanceAdvice: '',
      remark: '',
      identifying: '',
      growthTypeArr: [
        {
          id: '常绿',
          name: '常绿',
        },
        {
          id: '落叶',
          name: '落叶',
        },
      ],
      identifyingArr: [
        {
          id: 'baiyang',
          name: require('../../../../../assets/common/gardens/baiyang.png')
        },
        {
          id: 'guohuai',
          name: require('../../../../../assets/common/gardens/guohuai.png')
        },
        {
          id: 'hehuan',
          name: require('../../../../../assets/common/gardens/hehuan.png')
        },
        {
          id: 'lishu',
          name: require('../../../../../assets/common/gardens/lishu.png')
        },
        {
          id: 'luanshu',
          name: require('../../../../../assets/common/gardens/luanshu.png')
        },
        {
          id: 'nvzhen',
          name: require('../../../../../assets/common/gardens/nvzhen.png')
        },
        {
          id: 'other',
          name: require('../../../../../assets/common/gardens/other.png')
        },
        {
          id: 'wujiaofeng',
          name: require('../../../../../assets/common/gardens/wujiaofeng.png')
        },
        {
          id: 'wutong',
          name: require('../../../../../assets/common/gardens/wutong.png')
        },
        {
          id: 'yinghua',
          name: require('../../../../../assets/common/gardens/yinghua.png')
        },
        {
          id: 'yinxing',
          name: require('../../../../../assets/common/gardens/yinxing.png')
        },
      ],
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  computed: {
    getIconUrl () {
      return this.identifyingArr.find(item => item.id === this.identifying).name
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      this.$store.dispatch('getTreeVarietiesDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
      })
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.name) return this.$Message.info('请输入树种名称');
      if (!this.growthType) return this.$Message.info('请选择生长类型');
      if (!this.identifying) return this.$Message.info('请选择标识');
      let params = {
        name: this.name, //树种名称
        family: this.family, //科属
        growthType: this.growthType, //生长类型
        introduction: this.introduction, //介绍说明
        maintenanceAdvice: this.maintenanceAdvice, //养护建议
        status: this.status, //状态 0 禁用 1启用
        remark: this.remark, //备注
        identifying: this.identifying //标识
      }
      if (this.dataId) params.id = this.dataId
      this.$store.dispatch('createOrEditTreeVarieties', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.$store.dispatch('updateFamilyVarietiesList', true)
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.name = ''
      this.family = ''
      this.growthType = 1
      this.introduction = ''
      this.maintenanceAdvice = ''
      this.remark = ''
      this.identifying = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;

  span:first-child {
    font-weight: 700;
    font-size: 16px;
    padding-left: 10px;
    border-left: 2px solid #25bb96;
  }
}
</style>
