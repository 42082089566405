<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         @on-visible-change="onChangeVisible"
         width="600">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half"
            v-if="editType == 'curingCycle'">
          <p class="li-title"><span class="li-must">*</span>养护周期：</p>
          <Select v-model="maintenanceCycleGardens"
                  placeholder="养护周期">
            <Option :value="item.id"
                    v-for="item in maintenanceCycleGardensArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half"
            v-if="editType == 'technicalInformation'">
          <p class="li-title"><span class="li-must">*</span>技术资料：</p>
          <Select v-model="technical"
                  placeholder="技术资料">
            <Option :value="item.id"
                    v-for="item in technicalArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    title: {
      type: String,
      default: '园林档案'
    },
    visible: Boolean,
    dataId: String,
    type: String
  },
  data () {
    return {
      loading: false,
      editType: '',
      maintenanceCycleGardens: '',
      maintenanceCycleGardensArr: [
        {
          id: '每日',
          name: '每日',
        },
        {
          id: '每周',
          name: '每周',
        },
        {
          id: '半月',
          name: '半月',
        },
        {
          id: '每月',
          name: '每月',
        },
        {
          id: '季度',
          name: '季度',
        },
        {
          id: '年度',
          name: '年度',
        }
      ],
      technical: '',
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  computed: {
    ...mapGetters({
      'technicalArr': 'getConserveAllList',
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateConserveAllList'
    ]),
    init () {
      this.updateConserveAllList()
    },
    getDetail () {
      this.editType = this.type
      // this.$store.dispatch('getCarDetail', this.dataId).then(res => {
      //   for (let i in res) {
      //     this[i] = res[i]
      //   }
      // })
    },
    // 确定按钮
    onClickConfirm () {
      let params = {
        others: [{}]
      }
      if (this.editType == 'curingCycle') {
        params.others[0].maintenanceCycleGardens = this.maintenanceCycleGardens
      } else {
        params.others[0].technical = this.technical
      }
      console.log(params);
      if (this.dataId) params.ids = this.dataId
      this.$store.dispatch('createOrEditFacility', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.$emit('update:type', '')
        this.resetData()
      }
    },
    resetData () {
      this.maintenanceCycleGardens = ''
      this.technical = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>