<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <div class="section-title">
        <span>基础信息</span>
      </div>
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>设施类型：</p>
          <Cascader :data="typeArr"
                    v-model="type"
                    style="width:100%;"></Cascader>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>设施名称：</p>
          <Input v-model="name"
                 maxlength="30"
                 placeholder="设施名称"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">设施编号：</p>
          <Input v-model="number"
                 clearable
                 maxlength="10"
                 placeholder="设施编号"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="departName"
                        placeholder="所属机构"
                        clearable
                        :data="departTreeArr"
                        search
                        @onSelectChange="onChangeDepartment">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">责任人：</p>
          <MyTreeSelect :value="principal"
                        placeholder="责任人"
                        :data="principalTreeArr"
                        search
                        clearable
                        style="width:100%"
                        @onSelectChange="onChangePeoson">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">设施状态：</p>
          <Select v-model="status"
                  clearable
                  placeholder="设施状态">
            <Option :value="item.id"
                    v-for="item in statusArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">维护单位：</p>
          <MyTreeSelect :value="maintain"
                        placeholder="维护单位"
                        clearable
                        :data="departTreeArr"
                        search
                        @onSelectChange="onChangeMaintain">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>点位半径：</p>
          <Input v-model="radius"
                 maxlength="30"
                 v-floatLimit
                 placeholder="点位半径"
                 class="input-right-5"></Input>
          <span class="li-unit">米</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">启用日期：</p>
          <DatePicker :value="date"
                      type="date"
                      placeholder="启用日期"
                      @on-change="onChangeDate"></DatePicker>
        </li>
        <li class="form-ul-li">
          <p class="li-title">商圈：</p>
          <Select v-model="business"
                  clearable
                  placeholder="商圈">
            <Option :value="item.id"
                    v-for="item in businessArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>地址：</p>
          <Input v-model="site"
                 readonly
                 placeholder="地址">
          <Icon custom="i-icon icon-weizhishi"
                size="20"
                color="#25bb96"
                class="cursor-pointer"
                slot="suffix"
                @click="onClickSite" />
          </Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
        <li class="form-ul-li">
          <p class="li-title">文件多媒体：</p>
          <ul class="file-img">
            <UploadFile @uploadSuccess="uploadPicture"
                        :disabled="picture">
              <li>
                <el-image :src="picture"
                          fit="cover"
                          :preview-src-list="[picture]"
                          class="w-full h-full"
                          v-show="picture">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeletePicture"
                      v-show="picture" />
                <Icon custom="i-icon icon-tupianshangchuan"
                      size="40"
                      color="#B5B5B5"
                      v-show="!picture" />
              </li>
            </UploadFile>
          </ul>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="file-tip">
            图片：支持格式为png、jpg、jpeg、bmp，最多不能超过5张，最大不能超过1M；
          </div>
        </li>
      </ul>
      <div class="section-title">
        <span>拓展信息</span>
      </div>
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>科属树种：</p>
          <!-- <Input v-model="family"
                 maxlength="30"
                 v-stringLimit
                 placeholder="科属"></Input> -->
          <Cascader :data="familyVarietiesArr"
                    v-model="family"></Cascader>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>学名：</p>
          <Input v-model="scientificName"
                 maxlength="30"
                 v-stringLimit
                 placeholder="学名"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>树龄：</p>
          <Input v-model="treeAge"
                 maxlength="30"
                 v-floatLimit
                 placeholder="树龄"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>树高：</p>
          <Input v-model="treeHeight"
                 maxlength="30"
                 v-floatLimit
                 placeholder="树高"
                 class="input-right-5"></Input>
          <span class="li-unit">米</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>胸径：</p>
          <Input v-model="dbh"
                 maxlength="30"
                 v-floatLimit
                 placeholder="胸径"
                 class="input-right-5"></Input>
          <span class="li-unit">米</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>地径：</p>
          <Input v-model="groundDiameter"
                 maxlength="30"
                 v-floatLimit
                 placeholder="地径"
                 class="input-right-5"></Input>
          <span class="li-unit">米</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>树叶密度：</p>
          <Input v-model="leavesDensity"
                 maxlength="30"
                 v-floatLimit
                 placeholder="树叶密度"
                 class="input-right-5"></Input>
          <span class="li-unit">%</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>树冠状态：</p>
          <Select v-model="crown"
                  placeholder="树冠状态">
            <Option :value="item.id"
                    v-for="item in crownArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>生长形势：</p>
          <Select v-model="growthSituation"
                  placeholder="生长形势">
            <Option :value="item.id"
                    v-for="item in growthSituationArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>养护方式：</p>
          <Select v-model="curingWay"
                  placeholder="养护方式">
            <Option :value="item.id"
                    v-for="item in curingWayArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>养护周期：</p>
          <Select v-model="maintenanceCycle"
                  placeholder="养护周期">
            <Option :value="item.id"
                    v-for="item in maintenanceCycleArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>技术资料：</p>
          <Select v-model="technical"
                  placeholder="技术资料">
            <Option :value="item.id"
                    v-for="item in technicalArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">保护措施：</p>
          <Input v-model="safeguardProcedures"
                 clearable
                 maxlength="30"
                 v-stringLimit
                 placeholder="保护措施"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">介绍说明：</p>
          <Input v-model="introduction"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="介绍说明" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
    <!-- 选择地址 -->
    <PositionModal v-model="positionVisible"
                   :lng="lng"
                   :lat="lat"
                   @onClickConfirm="onChangeSite" />
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import MyTreeSelect from '../../../../common/MyTreeSelect.vue'
import UploadFile from '@/components/common/UploadFile'
import PositionModal from '@/components/product/main/template/PositionModal'
export default {
  components: {
    MyTreeSelect,
    UploadFile,
    PositionModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '名木古树'
    },
    dataId: String
  },
  data () {
    return {
      loading: false,
      type: [],
      name: '',
      number: '',
      departName: '',
      departId: '',
      principal: '',
      principalId: '',
      status: 0,
      maintain: '',
      maintainId: '',
      radius: '',
      date: '',
      business: '',
      remark: '',
      picture: '',
      family: [],
      scientificName: '',
      treeAge: '',
      treeHeight: '',
      dbh: '',
      groundDiameter: '',
      leavesDensity: '',
      crown: 1,
      growthSituation: 1,
      curingWay: 1,
      maintenanceCycle: 1,
      technical: '',
      safeguardProcedures: '',
      introduction: '',
      typeArr: [
        {
          value: 'tree',
          label: '名木古树',
          children: [
            {
              value: 'memoryOldTrees',
              label: '纪念名木'
            },
            {
              value: 'levelOfAncient',
              label: '一级古树'
            },
            {
              value: 'rarePreciousTrees',
              label: '珍稀名木'
            },
            {
              value: 'secondaryTrees',
              label: '二级古树'
            }
          ]
        }
      ],
      statusArr: [
        {
          id: 0,
          name: '正常',
        }, {
          id: 1,
          name: '废弃',
        }, {
          id: 2,
          name: '维修',
        }, {
          id: 3,
          name: '拆除',
        }, {
          id: 4,
          name: '暂停',
        },
      ],
      businessArr: [],
      crownArr: [
        {
          id: '茂盛',
          name: '茂盛',
        },
        {
          id: '良好',
          name: '良好',
        },
        {
          id: '稀疏',
          name: '稀疏',
        },
        {
          id: '光枝',
          name: '光枝',
        },
      ],
      growthSituationArr: [
        {
          id: '强',
          name: '强',
        },
        {
          id: '较强',
          name: '较强',
        },
        {
          id: '一般',
          name: '一般',
        },
        {
          id: '较弱',
          name: '较弱',
        },
        {
          id: '弱',
          name: '弱',
        },
      ],
      curingWayArr: [
        {
          id: '浇水排水',
          name: '浇水排水',
        },
        {
          id: '施肥',
          name: '施肥',
        },
        {
          id: '修剪',
          name: '修剪',
        },
        {
          id: '病虫害防治',
          name: '病虫害防治',
        },
      ],
      maintenanceCycleArr: [
        {
          id: '每日',
          name: '每日',
        },
        {
          id: '每周',
          name: '每周',
        },
        {
          id: '半月',
          name: '半月',
        },
        {
          id: '每月',
          name: '每月',
        },
        {
          id: '季度',
          name: '季度',
        },
        {
          id: '年度',
          name: '年度',
        }
      ],
      // 选择地址
      positionVisible: false,
      lng: '',
      lat: '',
      site: '',
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  computed: {
    ...mapGetters({
      'departTreeArr': 'getDepartmentTreeList',
      'principalTreeArr': 'getUserTreeList',
      'technicalArr': 'getConserveAllList',
      'familyVarietiesArr': 'getFamilyVarietiesList'
    })
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
      'updateUserTreeList',
      'updateConserveAllList',
      'updateFamilyVarietiesList'
    ]),
    init () {
      this.updateDepartmentTreeList()
      this.updateUserTreeList()
      this.updateConserveAllList()
      this.updateFamilyVarietiesList()
      this.getFacilityTypeList()
    },
    getDetail () {
      this.$store.dispatch('getFacilityDetail', this.dataId).then(res => {
        for (const i in res) {
          this[i] = res[i]
        }
        for (const j in res.others[0]) {
          this[j] = res.others[0][j]
        }
        // this.crown = parseInt(this.crown)
        // this.growthSituation = parseInt(this.growthSituation)
        this.family = res.others[0].family.split(',')
        this.type = [res.bigType, res.smallType]
        if (res.lnglat) {
          let lnglatArr = res.lnglat.split(',')
          this.lng = lnglatArr[0]
          this.lat = lnglatArr[1]
        }
        this.status = parseInt(res.status)
      })
    },
    // 获取设施类型
    getFacilityTypeList () {
      this.$http.getFacilityBigTypeAllList({
        type: 'tree'
      }).then(res => {
        if (res.code === 200) {
          let obj = res.result.find(item => {
            return item.name == '名木古树'
          })
          this.typeArr = [obj].map(item => {
            return {
              value: item.id,
              label: item.name,
              children: item.items.map(items => {
                return {
                  value: items.id,
                  label: items.name
                }
              })
            }
          })
        }
      })
    },
    // 选择所属机构
    onChangeDepartment (section) {
      this.departId = section.id
      this.departName = section.name
    },
    // 选择责任人
    onChangePeoson (section) {
      if (section.type !== 'depart') {
        this.principal = section.name
        this.principalId = section.id
      }
    },
    // 选择维护单位
    onChangeMaintain (section) {
      this.maintainId = section.id
      this.maintain = section.name
    },
    // 修改日期
    onChangeDate (date) {
      this.date = date
    },
    // 上传照片
    uploadPicture (arr) {
      if (arr.length > 0) {
        this.picture = arr[0].url
      }
    },
    // 删除图片
    onClickDeletePicture () {
      this.picture = ''
    },
    // 点击选择地址
    onClickSite () {
      this.positionVisible = true
    },
    // 修改地址
    onChangeSite (e) {
      this.site = e.address
      this.lng = e.lng
      this.lat = e.lat
    },
    // 确定按钮
    onClickConfirm () {
      if (!this.type) return this.$Message.info('请选择设施类型');
      if (!this.name) return this.$Message.info('请输入设施名称');
      if (!this.departId) return this.$Message.info('请选择所属机构');
      if (!this.radius) return this.$Message.info('请输入点位半径');
      if (!this.site) return this.$Message.info('请选择地址');
      if (this.family.lenght == 0) return this.$Message.info('请输入科属');
      if (!this.scientificName) return this.$Message.info('请输入学名');
      if (!this.treeAge) return this.$Message.info('请输入树龄');
      if (!this.treeHeight) return this.$Message.info('请输入树高');
      if (!this.dbh) return this.$Message.info('请输入胸径');
      if (!this.groundDiameter) return this.$Message.info('请输入地径');
      if (!this.leavesDensity) return this.$Message.info('请输入树叶密度');
      if (!this.crown) return this.$Message.info('请选择树冠状态');
      if (!this.growthSituation) return this.$Message.info('请选择生长形势');
      if (!this.curingWay) return this.$Message.info('请选择养护方式');
      if (!this.maintenanceCycle) return this.$Message.info('请选择养护周期');
      if (!this.technical) return this.$Message.info('请选择技术资料');
      let params = {
        name: this.name,
        bigType: this.type[0],
        smallType: this.type[1],
        number: this.number,
        departId: this.departId,
        principalId: this.principalId,
        status: this.status,
        maintainId: this.maintainId,
        radius: this.radius,
        date: this.date,
        business: this.business,
        lnglat: this.lng + ',' + this.lat,
        site: this.site,
        remark: this.remark,
        picture: this.picture,
        others: [{
          // 拓展资料
          family: this.family.join(','),
          scientificName: this.scientificName,
          treeAge: this.treeAge,
          treeHeight: this.treeHeight,
          dbh: this.dbh,
          groundDiameter: this.groundDiameter,
          leavesDensity: this.leavesDensity,
          crown: this.crown,
          growthSituation: this.growthSituation,
          curingWay: this.curingWay,
          maintenanceCycle: this.maintenanceCycle,
          technical: this.technical,
          safeguardProcedures: this.safeguardProcedures,
          introduction: this.introduction,
        }],
      }
      if (this.dataId) params.ids = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditFacility', params).then(res => {
        this.loading = false
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.type = []
      this.name = ''
      this.number = ''
      this.departName = ''
      this.departId = ''
      this.principal = ''
      this.principalId = ''
      this.status = 0
      this.maintain = ''
      this.maintainId = ''
      this.radius = ''
      this.date = ''
      this.business = ''
      this.lng = ''
      this.lat = ''
      this.site = ''
      this.remark = ''
      this.picture = ''
      this.family = []
      this.scientificName = ''
      this.treeAge = ''
      this.treeHeight = ''
      this.dbh = ''
      this.groundDiameter = ''
      this.leavesDensity = ''
      this.crown = 1
      this.growthSituation = 1
      this.curingWay = 1
      this.maintenanceCycle = 1
      this.technical = ''
      this.safeguardProcedures = ''
      this.introduction = ''
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
