<template>
  <Drawer :title="title"
          closable
          width="45"
          :value="visible"
          @on-visible-change="onChangeVisible">
    <div class="detail"
         v-if="data">
      <div class="detail-top">
        <div class="detail-top-title">
          <Icon custom="i-icon icon-lajishoujizhan"
                size="32"
                color="#ffffff"
                class="detail-top-title-icon" />
          <div class="detail-top-title-div">
            <p>{{data.name || '--'}}</p>
            <div><span>所属机构：</span>{{data.departName || '--'}}</div>
          </div>
        </div>
        <div class="detail-top-msg">
          <p>责任人：<span>{{data.principal || '--'}}</span></p>
          <p>点位半径：<span>{{data.radius || '--'}}</span></p>
          <p>维护单位：<span>{{data.maintain ||'--'}}</span></p>
        </div>
        <div>
          <el-image :src="data.picture"
                    fit="cover"
                    :preview-src-list="[data.picture]"
                    class="detail-top-img"
                    v-if="data.picture">
          </el-image>
          <span v-else>暂无图片</span>
        </div>
        <p>
          <Icon custom="i-icon icon-dingwei"
                size="16"
                color="#000" />
          <span>{{data.site}}</span>
        </p>
      </div>
      <Tabs>
        <TabPane label="基础概况"></TabPane>
      </Tabs>
      <div>
        <div class="section">
          <div class="section-title">
            <span>基础信息</span>
          </div>
          <ul class="section-cont">
            <li>
              <label>启用日期:</label>
              <span>{{data.date || '--'}}</span>
            </li>
            <li>
              <label>状态:</label>
              <span>{{data.status || '--'}}</span>
            </li>
            <li>
              <label>设施编号:</label>
              <span>{{data.number || '--'}}</span>
            </li>
            <li class="one-row">
              <label>备注:</label>
              <span>{{data.remark || '--'}}</span>
            </li>
          </ul>
        </div>
        <div class="section">
          <div class="section-title">
            <span>拓展信息</span>
          </div>
          <template v-if="data.others">
            <ul class="section-cont"
                v-for="(item,index) in data.others[0].details"
                :key='index'>
              <li class="one-row">
                <label>树种{{index+1}}:</label>
                <!-- <span>{{data.name || '--'}}</span> -->
              </li>
              <li>
                <label>主要树种:</label>
                <span>{{item.name || '--'}}</span>
              </li>
              <li>
                <label>数量:</label>
                <span>{{item.count || '--'}}</span>
              </li>
              <li>
                <label>树龄:</label>
                <span>{{item.age || '--'}}</span>
              </li>
              <li>
                <label>规格:</label>
                <span>{{item.spec || '--'}}</span>
              </li>
            </ul>
          </template>
          <ul class="section-cont"
              v-if="data.others">
            <!-- <li>
              <label>园林范围:</label>
              <span>{{data.others[0].gardenRange || '--'}}</span>
            </li> -->
            <li>
              <label>总面积:</label>
              <span>{{data.others[0].totalArea || '--'}}</span>
            </li>
            <li>
              <label>规划绿化面积:</label>
              <span>{{data.others[0].plannedGreenArea || '--'}}</span>
            </li>
            <li>
              <label>规划面积:</label>
              <span>{{data.others[0].greenArea || '--'}}</span>
            </li>
            <li>
              <label>物料设备:</label>
              <span>{{data.others[0].material || '--'}}</span>
            </li>
            <li>
              <label>养护车辆:</label>
              <span>{{data.others[0].maintainCar || '--'}}</span>
            </li>
            <li>
              <label>保洁人员:</label>
              <span>{{data.others[0].housekeeper || '--'}}</span>
            </li>
            <li>
              <label>养护工人:</label>
              <span>{{data.others[0].maintenanceWorkers || '--'}}</span>
            </li>
            <li>
              <label>健康树木:</label>
              <span>{{data.others[0].healthyTrees || '--'}}</span>
            </li>
            <li>
              <label>生长强树木:</label>
              <span>{{data.others[0].strongGrowingTree || '--'}}</span>
            </li>
            <li>
              <label>养护周期:</label>
              <span>{{data.others[0].maintenanceCycleGardens || '--'}}</span>
            </li>
            <li>
              <label>技术资料:</label>
              <span>{{data.others[0].technical || '--'}}</span>
            </li>
            <li>
              <label>介绍说明:</label>
              <span>{{data.others[0].introductionGardens || '--'}}</span>
            </li>
          </ul>
        </div>
        <div class="section"
             v-if="tableFacilityData.length != 0">
          <div class="section-title">
            <span>附属设施</span>
          </div>
          <div>
            <Table ref="facilityTable"
                   :columns="columnsFacility"
                   :data="tableFacilityData"
                   border
                   stripe
                   :loading="tableFacilityLoading">
            </Table>
          </div>
        </div>
        <div class="section">
          <div class="section-title">
            <span>绑定设备</span>
          </div>
          <div>
            <Table ref="equipmentTable"
                   :columns="columnsEquipment"
                   :data="tableEquipmentData"
                   border
                   stripe
                   :loading="tableEquipmentLoading">
            </Table>
          </div>
        </div>
      </div>
    </div>
  </Drawer>
</template>

<script>
export default {
  components: {},
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: "设施档案"
    },
    dataId: String
  },
  data () {
    return {
      data: {},
      columnsFacility: [
        {
          title: '序号',
          width: 45,
          align: 'center',
          render: (h, params) => {
            let index = params.index + 1
            let num = (this.pageNum - 1) * this.pageSize + index
            return h('span', num)
          }
        },
        {
          title: '设施名称',
          key: 'manageName',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设施编号',
          key: 'managePhone',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设施类型',
          key: 'managePhone',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '状态',
          key: 'managePhone',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '地址',
          key: 'managePhone',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        }
      ],
      tableFacilityData: [],
      tableFacilityLoading: false,
      columnsEquipment: [
        {
          title: '设备编号',
          key: 'equipcode',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备类型',
          key: 'equiptypeid',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '设备通讯号',
          key: 'communicationcode',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        },
        {
          title: '厂商',
          key: 'manufacturerid',
          mainWidth: 60,
          tooltip: true,
          align: 'center'
        }
      ],
      tableEquipmentData: [],
      tableEquipmentLoading: false,
    };
  },
  watch: {
    dataId (newValue) {
      newValue && this.getDetail()
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      this.$store.dispatch('getFacilityDetail', this.dataId).then(res => {
        this.data = res
        switch (res.status) {
          case "0":
            this.data.status = '正常'
            break;
          case "1":
            this.data.status = '废弃'
            break;
          case "2":
            this.data.status = '维修'
            break;
          case "3":
            this.data.status = '拆除'
            break;
          case "4":
            this.data.status = '暂停'
            break;
          default:
            break;
        }
        console.log(this.data.status);
        this.getEquipmentList()
      })
    },
    
    getEquipmentList () {
      let params = {
        page: this.pageNum,
        size: this.pageSize,
        bindtype: this.data.facltypeid,
        bindobjid: this.dataId,
      }
      this.$http.getEquipmentList(params).then(res => {
        if (res.code === 200) {
          this.totalPage = res.result.total
          this.tableEquipmentData = res.result.equiplist
        }
      })
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.resetData()
        this.$emit('update:dataId', '')
        this.$emit('onChange', false)
      }
    },
    resetData () {
      this.data = {}
    }
  },
}
</script>

<style lang='scss' scoped>
.detail {
  .detail-top {
    padding: 8px 27px 0;
    .detail-top-title {
      display: flex;
      margin-bottom: 10px;
      .detail-top-title-icon {
        display: block;
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background: #0079fe;
        text-align: center;
        line-height: 50px;
        margin-right: 7px;
      }
      .detail-top-title-div {
        color: #333;
        font-size: 14px;
        p {
          font-size: 18px;
        }
        div {
          color: #6a6b75;
        }
      }
    }
    .detail-top-msg {
      display: flex;
      font-size: 13px;
      color: #333;
      span {
        color: #0079fe;
        padding-right: 45px;
      }
    }
    .detail-top-img {
      width: 170px;
      height: 108px;
    }
  }
  .section {
    .section-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 50px;
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;

      span:first-child {
        font-weight: 700;
        // font-size: 16px;
        padding-left: 10px;
        border-left: 2px solid #25bb96;
      }
    }
    .section-cont {
      display: flex;
      flex-wrap: wrap;
      padding: 0px 15px;
      padding-bottom: 10px;
      li {
        width: 50%;
        line-height: 34px;
        label {
          display: inline-block;
          min-width: 100px;
          text-align: right;
          margin-right: 10px;
        }
      }
      .one-row {
        width: 100%;
      }
      .img-row {
        display: flex;
        align-items: flex-start;
        img {
          width: 50%;
          margin-right: 10px;
        }
        span {
          margin-right: 10px;
        }
      }
    }
    .noData {
      p {
        color: #000;
      }
    }
  }
}
</style>