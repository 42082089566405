<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="600"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <div class="section-title">
        <span>基础信息</span>
      </div>
      <ul class="form-ul">
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>设施类型：</p>
          <Cascader :data="typeArr"
                    v-model="type"
                    style="width:100%;"></Cascader>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>设施名称：</p>
          <Input v-model="name"
                 placeholder="设施名称"
                 maxlength="30"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">设施编号：</p>
          <Input v-model="number"
                 maxlength="10"
                 placeholder="设施编号"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>所属机构：</p>
          <MyTreeSelect :value="departName"
                        placeholder="所属机构"
                        clearable
                        :data="departTreeArr"
                        search
                        @onSelectChange="onChangeDepart">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">责任人：</p>
          <MyTreeSelect :value="principal"
                        placeholder="责任人"
                        :data="principalTreeArr"
                        search
                        @onSelectChange="onChangePeoson">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">设施状态：</p>
          <Select v-model="status"
                  placeholder="设施状态">
            <Option :value="item.id"
                    v-for="item in statusArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">维护单位：</p>
          <MyTreeSelect :value="maintain"
                        placeholder="所属机构"
                        :data="departTreeArr"
                        search
                        clearable
                        @onSelectChange="onChangeMaintain">
          </MyTreeSelect>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>点位半径：</p>
          <Input v-model="radius"
                 placeholder="点位半径"
                 class="input-right-5"></Input>
          <span class="li-unit">米</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">启用日期：</p>
          <DatePicker :value="date"
                      type="date"
                      placeholder="启用日期"
                      @on-change="onChangeDate"></DatePicker>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">商圈：</p>
          <Select v-model="business"
                  placeholder="商圈">
            <Option :value="item.id"
                    v-for="item in businessArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="li-title"><span class="li-must">*</span>地址：</p>
          <Input v-model="site"
                 readonly
                 placeholder="地址">
          <Icon custom="i-icon icon-weizhishi"
                size="20"
                color="#25bb96"
                class="cursor-pointer"
                slot="suffix"
                @click="onClickSite" />
          </Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">区域：</p>
          <div class="cursor-pointer"
               style="color:rgb(37, 187, 150);line-height:32px"
               @click="onClickDrawGrid">{{gridId && gridId!=0 ? '查看区域' : '新增区域'}}</div>
          <!-- <Input v-model="gridName"
                 readonly
                 placeholder="">
          <Icon custom="i-icon icon-quyu1"
                size="20"
                color="#25bb96"
                class="cursor-pointer"
                slot="suffix"
                @click="onClickDrawGrid" />
          </Input> -->
        </li>
        <li class="form-ul-li">
          <p class="li-title">备注：</p>
          <Input v-model="remark"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="备注" />
        </li>
        <li class="form-ul-li">
          <p class="li-title">多媒体文件：</p>
          <ul class="file-img">
            <UploadFile @uploadSuccess="uploadPicture"
                        :disabled="picture">
              <li>
                <el-image :src="picture"
                          fit="cover"
                          :preview-src-list="[picture]"
                          class="w-full h-full"
                          v-show="picture">
                </el-image>
                <Icon type="md-close-circle"
                      size="24"
                      color="#fa5357"
                      class="file-img-deleteIcon"
                      @click.stop="onClickDeletePicture"
                      v-show="picture" />
                <Icon custom="i-icon icon-tupianshangchuan"
                      size="40"
                      color="#B5B5B5"
                      v-show="!picture" />
              </li>
            </UploadFile>
          </ul>
        </li>
        <li class="form-ul-li">
          <p class="li-title"></p>
          <div class="file-tip">
            图片：支持格式为png、jpg、jpeg、bmp，最多不能超过5张，最大不能超过1M；
          </div>
        </li>
      </ul>
      <div class="section-title">
        <span>拓展信息</span>
      </div>
      <ul class="form-ul tree"
          v-for="(item,index) in treeArr"
          :key="index">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>主要树种：</p>
          <Select v-model="item.id"
                  placeholder="主要树种"
                  label-in-value
                  @on-change="value => onChangeTreeVarieties(value, index)">
            <Option :value="items.id"
                    v-for="items in treeVarietiesArr"
                    :key="items.id">{{items.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>数量：</p>
          <Input v-model="item.count"
                 placeholder="数量"
                 class="input-right-5"
                 maxlength="30"
                 v-intLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>树龄：</p>
          <!-- <Select v-model="item.age"
                  placeholder="树龄">
            <Option :value="items.id"
                    v-for="items in ageArr"
                    :key="items.id">{{items.name}}
            </Option>
          </Select> -->
          <Input v-model="item.age"
                 placeholder="树龄"
                 maxlength="30"
                 v-intLimit></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>规格：</p>
          <Input v-model="item.spec"
                 placeholder="规格"
                 class="input-right-5"
                 maxlength="30"
                 v-intLimit></Input>
        </li>
        <Icon custom="i-icon icon-guanbi"
              size="13"
              color="#fff"
              class="delete-icon"
              v-if="index != 0"
              @click.stop="onClickDeleteTree(index)" />
      </ul>
      <Button class="m-b-15"
              type="primary"
              @click.stop="onClickAddTree">添加树种</Button>
      <ul class="form-ul">
        <!-- <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>园林范围：</p>
          <Input v-model="gardenRange"
                 maxlength="30"
                 v-floatLimit
                 placeholder="园林范围"></Input>
        </li> -->
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>总面积：</p>
          <Input v-model="totalArea"
                 maxlength="30"
                 v-floatLimit
                 placeholder="总面积"
                 class="input-right-5"></Input>
          <span class="li-unit">亩</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>规划绿化面积：</p>
          <Input v-model="plannedGreenArea"
                 maxlength="30"
                 v-floatLimit
                 placeholder="规划绿化面积"
                 class="input-right-5"></Input>
          <span class="li-unit">亩</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>绿化面积：</p>
          <Input v-model="greenArea"
                 maxlength="30"
                 v-floatLimit
                 placeholder="绿化面积"
                 class="input-right-5"></Input>
          <span class="li-unit">亩</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>总数量：</p>
          <Input v-model="totalCount"
                 maxlength="30"
                 v-intLimit
                 placeholder="总数量"
                 class="input-right-5"></Input>
          <span class="li-unit">棵</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">物料设备：</p>
          <Input v-model="material"
                 maxlength="30"
                 v-stringLimit
                 placeholder="物料设备"></Input>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">养护车辆：</p>
          <Input v-model="maintainCar"
                 maxlength="30"
                 v-intLimit
                 placeholder="养护车辆"
                 class="input-right-5"></Input>
          <span class="li-unit">辆</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">保洁人员：</p>
          <Input v-model="housekeeper"
                 maxlength="30"
                 v-intLimit
                 placeholder="保洁人员"
                 class="input-right-5"></Input>
          <span class="li-unit">人</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">养护工人：</p>
          <Input v-model="maintenanceWorkers"
                 maxlength="30"
                 v-intLimit
                 placeholder="养护工人"
                 class="input-right-5"></Input>
          <span class="li-unit">人</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">健康树木：</p>
          <Input v-model="healthyTrees"
                 maxlength="30"
                 v-intLimit
                 placeholder="健康树木"
                 class="input-right-5"></Input>
          <span class="li-unit">棵</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title">生长强树木：</p>
          <Input v-model="strongGrowingTree"
                 maxlength="30"
                 v-intLimit
                 placeholder="生长强树木"
                 class="input-right-5"></Input>
          <span class="li-unit">棵</span>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>养护周期：</p>
          <Select v-model="maintenanceCycleGardens"
                  placeholder="养护周期">
            <Option :value="item.id"
                    v-for="item in maintenanceCycleGardensArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>技术资料：</p>
          <Select v-model="technical"
                  placeholder="技术资料">
            <Option :value="item.id"
                    v-for="item in technicalArr"
                    :key="item.id">{{item.name}}
            </Option>
          </Select>
        </li>
        <li class="form-ul-li">
          <p class="li-title">介绍说明：</p>
          <Input v-model="introductionGardens"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="介绍说明" />
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>

    <!-- 选择地址 -->
    <PositionModal v-model="positionVisible"
                   :lng="lng"
                   :lat="lat"
                   @onClickConfirm="onChangeSite" />

    <!-- 新增 / 修改网格 -->
    <GridModal v-model="gridVisible"
               :width="width"
               @onClickConfirm='onClickDrawConfirm'></GridModal>

    <GardensRegionModal v-model="gardensRegionVisible"
                        :dataId.sync="gardensRegionId"
                        @onClickConfirm='onClickDrawConfirm'></GardensRegionModal>
  </Modal>
</template>

<script>
import MyTreeSelect from '@/components/common/MyTreeSelect'
import UploadFile from '@/components/common/UploadFile';
import { mapActions, mapGetters } from 'vuex';
import PositionModal from '@/components/product/main/template/PositionModal'
import GridModal from '@/components/product/admin/modal/work/GridModal'
import GardensRegionModal from '@/components/product/admin/modal/archives/GardensRegionModal'
export default {
  components: {
    MyTreeSelect,
    UploadFile,
    PositionModal,
    GridModal,
    GardensRegionModal
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '园林绿地'
    },
    dataId: String,
    width: Number
  },
  data () {
    return {
      loading: false,
      type: [],
      name: '',
      number: '',
      departName: '',
      departId: '',
      principal: '',
      status: 0,
      maintain: '',
      maintainId: '',
      radius: '',
      date: '',
      business: '',
      site: '',
      remark: '',
      picture: '',
      gridName: '',
      gridId: '',
      // gardenRange: '',
      totalArea: '',
      plannedGreenArea: '',
      greenArea: '',
      totalCount: '',
      material: '',
      maintainCar: '',
      housekeeper: '',
      maintenanceWorkers: '',
      healthyTrees: '',
      strongGrowingTree: '',
      maintenanceCycleGardens: '',
      technical: '',
      introductionGardens: '',
      treeArr: [
        {
          name: '',
          count: '',
          age: '',
          spec: ''
        },
      ],
      statusArr: [
        {
          id: 0,
          name: '正常',
        }, {
          id: 1,
          name: '废弃',
        }, {
          id: 2,
          name: '维修',
        }, {
          id: 3,
          name: '拆除',
        }, {
          id: 4,
          name: '暂停',
        },
      ],
      businessArr: [],
      typeArr: [],
      maintenanceCycleGardensArr: [
        {
          id: '每日',
          name: '每日',
        },
        {
          id: '每周',
          name: '每周',
        },
        {
          id: '半月',
          name: '半月',
        },
        {
          id: '每月',
          name: '每月',
        },
        {
          id: '季度',
          name: '季度',
        },
        {
          id: '年度',
          name: '年度',
        }
      ],
      ageArr: [
        {
          id: '小于3年',
          name: '小于3年'
        },
        {
          id: '3~5年',
          name: '3~5年'
        },
        {
          id: '5~10年',
          name: '5~10年'
        },
        {
          id: '10~20年',
          name: '10~20年'
        },
        {
          id: '20年以上',
          name: '20年以上'
        },
      ],
      // 选择地址
      positionVisible: false,
      lng: '',
      lat: '',
      site: '',
      // 画网格
      gridVisible: false,
      zIndex: 500,
      // 区域
      gardensRegionVisible: false,
      gardensRegionId: ''
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  computed: {
    ...mapGetters({
      'departTreeArr': 'getDepartmentTreeList',
      'principalTreeArr': 'getUserTreeList',
      'technicalArr': 'getConserveAllList',
      'treeVarietiesArr': 'getTreeVarietiesAllList'
    }),
    // treeVarietiesArr () {
    //   let arr = this.$store.getters.getTreeVarietiesAllList.map(item => {
    //     item.id = item.name
    //     return item
    //   })
    //   return arr
    // }
  },
  mounted () {
    this.init()
  },
  methods: {
    ...mapActions([
      'updateDepartmentTreeList',
      'updateUserTreeList',
      'updateTreeVarietiesAllList',
      'updateConserveAllList',
    ]),
    init () {
      this.updateDepartmentTreeList()
      this.updateUserTreeList()
      this.updateTreeVarietiesAllList()
      this.updateConserveAllList()
      this.getFacilityTypeList()
    },
    getDetail () {
      this.$store.dispatch('getFacilityDetail', this.dataId).then(res => {
        for (const i in res) {
          this[i] = res[i]
        }
        for (const j in res.others[0]) {
          this[j] = res.others[0][j]
        }
        this.treeArr = res.others[0].details
        this.type = [res.bigType, res.smallType]
        if (res.lnglat) {
          let lnglatArr = res.lnglat.split(',')
          this.lng = lnglatArr[0]
          this.lat = lnglatArr[1]
        }
        this.status = parseInt(res.status)
      })
    },
    // 获取设施类型
    getFacilityTypeList () {
      this.$http.getFacilityBigTypeAllList({
        type: 'tree'
      }).then(res => {
        if (res.code === 200) {
          let obj = res.result.find(item => {
            return item.name == '园林绿化'
          })
          this.typeArr = [obj].map(item => {
            return {
              value: item.id,
              label: item.name,
              children: item.items.map(items => {
                return {
                  value: items.id,
                  label: items.name
                }
              })
            }
          })
        }
      })
    },
    // 选择机构
    onChangeDepart (section) {
      this.departId = section.id
      this.departName = section.name
    },
    // 选择责任人
    onChangePeoson (section) {
      if (section.type !== 'depart') {
        this.principalId = section.id
        this.principal = section.name
      }
    },
    // 选择维护单位
    onChangeMaintain (section) {
      this.maintainId = section.id
      this.maintain = section.name
    },
    // 修改日期
    onChangeDate (date) {
      this.date = date
    },
    // 点击选择地址
    onClickSite () {
      this.positionVisible = true
    },
    // 修改地址
    onChangeSite (e) {
      this.site = e.address
      this.lng = e.lng
      this.lat = e.lat
    },
    // 上传照片
    uploadPicture (arr) {
      if (arr.length > 0) {
        this.picture = arr[0].url
      }
    },
    // 删除图片
    onClickDeletePicture () {
      this.picture = ''
    },
    // 画网格
    onClickDrawGrid () {
      // this.gridVisible = true
      this.gardensRegionVisible = true
      if (this.gridId && this.gridId != 0) {
        this.gardensRegionId = this.gridId
      }
    },
    // 画网格回调
    onClickDrawConfirm (data) {
      // this.gridName = data.name
      this.gridId = data.gridId
    },
    // 添加树种
    onClickAddTree () {
      if (this.treeArr.length == 10) {
        this.$Message.info({
          content: '最多只能添加十条！'
        })
        return
      }
      this.treeArr.push({
        name: '',
        count: '',
        age: '',
        spec: ''
      })
    },
    // 选择树种
    onChangeTreeVarieties (data, index) {
      if (data) {
        this.treeArr[index].name = data.label
        this.treeArr[index].id = data.value
      }
    },
    // 删除树种
    onClickDeleteTree (index) {
      this.treeArr.splice(index, 1)
    },
    getEepetitionArr () {

    },
    // 确定按钮
    onClickConfirm () {
      if (this.type.length == 0) return this.$Message.info('请选择设施类型');
      if (!this.name) return this.$Message.info('请输入设施名称');
      if (!this.departId) return this.$Message.info('请选择所属机构');
      if (!this.radius) return this.$Message.info('请输入点位半径');
      if (!this.site) return this.$Message.info('请选择地址');
      // if (!this.gridId) return this.$Message.info('请选择区域');
      if (!this.treeArr[0].name) return this.$Message.info('请输入树种名称');
      if (!this.treeArr[0].count) return this.$Message.info('请输入树种数量');
      if (!this.treeArr[0].age) return this.$Message.info('请输入树种树龄');
      if (!this.treeArr[0].spec) return this.$Message.info('请选择树种规格');
      // if (!this.gardenRange) return this.$Message.info('请输入园林范围');
      if (!this.totalArea) return this.$Message.info('请输入总面积');
      if (!this.plannedGreenArea) return this.$Message.info('请输入规划绿化面积');
      if (!this.greenArea) return this.$Message.info('请输入绿化面积');
      if (!this.totalCount) return this.$Message.info('请输入树木总数量');
      if (!this.maintenanceCycleGardens) return this.$Message.info('请输入养护周期');
      if (!this.technical) return this.$Message.info('请输入技术资料');
      let arr = this.treeArr.map(item => {
        return item.name + ',' + item.age + ',' + item.spec
      })
      Array.prototype.duplicate = function () {
        var tmp = [];
        this.concat().sort().sort(function (a, b) {
          if (a == b && tmp.indexOf(a) === -1) tmp.push(a);
        });
        return tmp;
      }
      if (arr.duplicate().length > 0) return this.$Message.info('请勿输入相同树种信息');
      let params = {
        name: this.name,
        bigType: this.type[0],
        smallType: this.type[1],
        number: this.number,
        departId: this.departId,
        principalId: this.principalId,
        status: this.status,
        maintainId: this.maintainId,
        radius: this.radius,
        date: this.date,
        business: this.business,
        lnglat: this.lng + ',' + this.lat,
        site: this.site,
        gridId: this.gridId,
        remark: this.remark,
        picture: this.picture,
        others: [{
          // gardenRange: this.gardenRange,
          totalArea: this.totalArea,
          plannedGreenArea: this.plannedGreenArea,
          greenArea: this.greenArea,
          totalCount: this.totalCount,
          material: this.material,
          maintainCar: this.maintainCar,
          housekeeper: this.housekeeper,
          maintenanceWorkers: this.maintenanceWorkers,
          healthyTrees: this.healthyTrees,
          strongGrowingTree: this.strongGrowingTree,
          maintenanceCycleGardens: this.maintenanceCycleGardens,
          technical: this.technical,
          introductionGardens: this.introductionGardens,
          details: this.treeArr
        }]
      }
      if (this.dataId) params.ids = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditFacility', params).then(res => {
        this.loading = false
        this.$emit('onClickConfirm')
        this.$emit('onChange', false)
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.type = []
      this.name = ''
      this.number = ''
      this.departName = ''
      this.departId = ''
      this.principalId = ''
      this.principal = ''
      this.status = 0
      this.maintain = ''
      this.maintainId = ''
      this.radius = ''
      this.date = ''
      this.business = ''
      this.lng = ''
      this.lat = ''
      this.site = ''
      this.remark = ''
      this.picture = ''
      // this.gardenRange = ''
      this.totalArea = ''
      this.plannedGreenArea = ''
      this.greenArea = ''
      this.totalCount = ''
      this.material = ''
      this.maintainCar = ''
      this.housekeeper = ''
      this.maintenanceWorkers = ''
      this.healthyTrees = ''
      this.strongGrowingTree = ''
      this.maintenanceCycleGardens = ''
      this.technical = ''
      this.introductionGardens = ''
      this.gardensRegionId = ''
      this.gridId = 0
      this.treeArr = [{
        name: '',
        count: '',
        age: '',
        spec: ''
      }]
    }
  }
}
</script>

<style lang="scss" scoped>
.section-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;

  span:first-child {
    font-weight: 700;
    font-size: 16px;
    padding-left: 10px;
    border-left: 2px solid #25bb96;
  }
}
.tree {
  padding-top: 15px;
  background: #f2f2f2;
  margin-bottom: 10px;
  position: relative;
  .delete-icon {
    position: absolute;
    right: 3px;
    top: 3px;
    border-radius: 50%;
    background: red;
    padding: 1px;
    line-height: 1;
    cursor: pointer;
  }
}
.tree:last-child {
  margin-bottom: 5px;
}
</style>
