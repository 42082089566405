<template>
  <Modal :value="visible"
         :title="dataId ? '修改' + title : '新增' + title"
         width="900"
         @on-visible-change="onChangeVisible"
         class-name="vertical-center-modal">
    <div class="modal-cont">
      <ul class="form-ul">
        <li class="form-ul-li half">
          <p class="li-title"><span class="li-must">*</span>标题：</p>
          <Input v-model="name"
                 maxlength="30"
                 v-stringLimit
                 placeholder="标题"></Input>
        </li>
        <li class="form-ul-li half">
          <RadioGroup v-model="maintain">
            <Radio label="园林养护">
              <span>园林养护</span>
            </Radio>
            <Radio label="古树养护">
              <span>古树养护</span>
            </Radio>
          </RadioGroup>
        </li>
        <li class="form-ul-li">
          <p class="li-title">适用范围：</p>
          <Input v-model="sphereApplication"
                 maxlength="30"
                 v-stringLimit
                 placeholder="适用范围"></Input>
        </li>
        <li class="form-ul-li">
          <p class="li-title">摘要：</p>
          <Input v-model="abstract"
                 maxlength="-1"
                 :rows="3"
                 type="textarea"
                 placeholder="摘要" />
        </li>
        <li class="form-ul-li">
          <p class="li-title">内容：</p>
          <WangEnduit v-model="cont"></WangEnduit>
        </li>
      </ul>
    </div>
    <div slot="footer">
      <Button @click.stop="onClickCancel">取消</Button>
      <Button type="primary"
              :loading="loading"
              @click.stop="onClickConfirm">确定</Button>
    </div>
  </Modal>
</template>

<script>
import WangEnduit from '@/components/common/WangEnduit'
export default {
  components: {
    WangEnduit
  },
  model: {
    prop: 'visible',
    event: 'onChange'
  },
  props: {
    visible: Boolean,
    title: {
      type: String,
      default: '养护信息'
    },
    dataId: String,
  },
  data () {
    return {
      loading: false,
      name: '',
      maintain: '园林养护',
      sphereApplication: '',
      abstract: '',
      cont: ''
    }
  },
  watch: {
    dataId (newVal) {
      newVal && this.getDetail()
    }
  },
  computed: {
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {

    },
    getDetail () {
      this.$store.dispatch('getConserveDetail', this.dataId).then(res => {
        for (let i in res) {
          this[i] = res[i]
        }
      })
    },
    // 确定按钮
    onClickConfirm () {
      console.log(this.maintain);
      if (!this.name) return this.$Message.info('请输入标题');
      let params = {
        name: this.name, //标题
        maintain: this.maintain, //知识类别
        sphereApplication: this.sphereApplication, //使用范围
        abstract: this.abstract, //摘要
        cont: this.cont
      }
      if (this.dataId) params.id = this.dataId
      this.loading = true
      this.$store.dispatch('createOrEditConserve', params).then(res => {
        this.$emit('onClickConfirm', params)
        this.$emit('onChange', false)
        this.loading = false
      }).catch(err => {
        this.loading = false
      })
    },
    onClickCancel () {
      this.$emit('onClickCancel')
      this.$emit('onChange', false)
    },
    onChangeVisible (boo) {
      if (!boo) {
        this.$emit('onChange', false)
        this.$emit('update:dataId', '')
        this.resetData()
      }
    },
    resetData () {
      this.name = ''
      this.maintain = '园林养护'
      this.sphereApplication = ''
      this.abstract = ''
      this.cont = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-cont {
  max-height: none;
}
</style>
